<template>
  <router-view></router-view>

</template>

<script>

export default {
  name: 'App',
  components: {

  }
}
</script>

<style lang="scss">

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: #333333;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
img {
  display: block;
  vertical-align: top;
}

#app {
  top: 0;
  left: 0;
  right: 0;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  //width: 100%;
  //height: 100%;
  position: absolute;
}
.main-container{
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}
.svg-icon{
  width: 100% !important;
  height: 100% !important;
}

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

</style>
