<template>

</template>

<script>
export default {
  name: "supplierAllBack",
  data(){
    this.$router.replace({
      path:'/' 
    })
  }
}
</script>

<style scoped>

</style>