import { createApp } from 'vue'
import App from './App.vue'


import './utils/rem';
import router from './router'

import vant from 'vant';
import 'vant/lib/index.css';

import { VueSvgIconPlugin } from '@yzfe/vue3-svgicon'
import '@yzfe/svgicon/lib/svgicon.css'

import VueCookies from 'vue-cookies'

import jquery from 'jquery'
// Vue.prototype.$ = jquery


const app = createApp(App).use(VueSvgIconPlugin, { tagName: 'icon' })

app.use(router)
app.use(vant);
// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
app.use(VueCookies)

app.mount('#app')
